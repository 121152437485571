import URL_ROUTE from '../constants/index';

const token = sessionStorage.getItem('token');

class Api {
    async login(email, pass, ip) {
        let data = {
            email,
            pass,
            ip
        };
        const query = await fetch(`${URL_ROUTE.LOGIN}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchPatientAppoinments(npi) {
        let data = {
            npi
        };
        const query = await fetch(`${URL_ROUTE.SEARCH_PATIENTS_APPOINTMENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async uploadEvidences(idappointment, nameuser, upload) {
        let formData = new FormData();
        formData.append('idappointment', idappointment);
        formData.append('nameuser', nameuser);
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.UPLOAD_EVIDENCES}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async createAppointment(npi, name, lastname, datebirth, phone, email, address, assure, which, iduser, date, hour, nameuser, description, idtypeappointment, idinsurance) {
        let data = {
            npi,
            name,
            lastname,
            datebirth,
            phone,
            email,
            address,
            assure,
            which,
            iduser,
            date,
            hour,
            nameuser,
            description,
            idtypeappointment,
            idinsurance
        };
        const query = await fetch(`${URL_ROUTE.CREATE_APPOINTMENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async loadInsurance(idtop) {
        let data = {
            idtop
        };
        const query = await fetch(`${URL_ROUTE.LOAD_INSURANCE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async loadAppointments(idstatus) {
        let data = {
            idstatus
        };
        const query = await fetch(`${URL_ROUTE.LIST_APPOINTMENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async changeStatusAppointment(id, idstatus, nameuser) {
        let data = {
            id,
            idstatus,
            nameuser
        };
        const query = await fetch(`${URL_ROUTE.CHANGE_STATUS_APPOINTMENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listFileAppointments(idappointment) {
        let data = {
            idappointment
        };
        const query = await fetch(`${URL_ROUTE.LIST_FILE_APPOINTMENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createReffered(phone, name, iduser) {
        let data = {
            phone,
            name,
            iduser
        };
        const query = await fetch(`${URL_ROUTE.CREATE_REFFERED}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listRefferedAll() {
        const query = await fetch(`${URL_ROUTE.LIST_REFFERED_ALL}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listReffered(iduser) {
        let data = {
            iduser
        };
        const query = await fetch(`${URL_ROUTE.LIST_REFFERED_AGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async changeStatusReffered(idstatus, id) {
        let data = {
            idstatus,
            id
        };
        const query = await fetch(`${URL_ROUTE.UPDATE_REFFERED}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAppStatusUser(idstatus, iduser) {
        let data = {
            idstatus,
            iduser
        };
        const query = await fetch(`${URL_ROUTE.LIST_APP_STATUS_USER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async countAppointmentTotalDayMonth(idstatus) {
        let data = {
            idstatus
        };
        const query = await fetch(`${URL_ROUTE.COUNT_APPOINTMENT_TOTAL_DAY_MONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createTracker(idappointment, description, idstatus, iduser, nameuser) {
        let data = {
            idappointment,
            description,
            idstatus,
            iduser,
            nameuser
        };
        const query = await fetch(`${URL_ROUTE.CREATE_TRACKER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listTracker(idappointment) {
        let data = {
            idappointment
        };
        const query = await fetch(`${URL_ROUTE.LIST_TRACKER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async editDataUser(id, name, lastname, phone, email, address, idappointment, date, hour, description, nameuser) {
        let data = {
            id,
            name,
            lastname,
            phone,
            email,
            address,
            idappointment,
            date,
            hour,
            description,
            nameuser
        };
        const query = await fetch(`${URL_ROUTE.EDIT_DATA_USER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async loadparameter(idtop) {
        let data = {
            idtop
        };
        const query = await fetch(`${URL_ROUTE.LOAD_PARAMETER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createformobamacare(npi, name, lastname, age, phone, email, address, zone, idstatusmigratorie, work, assuremedic, ssn, accountbank, dependients, incometax, iduser, nameuser, date, hour, description, code) {
        console.log(nameuser);
        let data = {
            npi,
            name,
            lastname,
            age,
            phone,
            email,
            address,
            zone,
            idstatusmigratorie,
            work,
            assuremedic,
            ssn,
            accountbank,
            dependients,
            incometax,
            iduser,
            nameuser,
            date,
            hour,
            description,
            code
        };
        const query = await fetch(`${URL_ROUTE.CREATE_FORM_OBAMA_CARE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        return query;
    }

    async liststatus(idstatustracker) {
        let data = {
            idstatustracker
        };
        const query = await fetch(`${URL_ROUTE.LIST_STATUS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async liststatussupervisor(idstatustracker) {
        let data = {
            idstatustracker
        };
        const query = await fetch(`${URL_ROUTE.LIST_STATUS_SUPERVISOR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchpatients(npi) {
        let data = {
            npi
        };
        const query = await fetch(`${URL_ROUTE.SEARCH_PATIENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listtracking(idobamacare) {
        let data = {
            idobamacare
        };
        const query = await fetch(`${URL_ROUTE.LIST_TRACKING}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createtracking(idobamacare, description, idstatus, iduser, nameuser, idagent) {
        let data = {
            idobamacare,
            description,
            idstatus,
            iduser,
            nameuser,
            idagent
        };
        const query = await fetch(`${URL_ROUTE.CREATE_TRACKING}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatetracking(id, description, idagent) {
        let data = {
            id,
            description,
            idagent
        };
        const query = await fetch(`${URL_ROUTE.UPDATE_TRACKING}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    /* upload */

    async uploadexcelfiles(agent, upload) {
        let formData = new FormData();
        formData.append('agent', agent);
        formData.append('upload', upload);

        const query = await fetch(`${URL_ROUTE.UPLOADEXCELFILE}`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + token
            },
            body: formData
        });
        return query;
    }

    async lsitphoneagentall(day) {
        let data = {
            day
        }
        const query = await fetch(`${URL_ROUTE.LISTPHONEAGENTALL}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAllUser(idagent, day) {
        let data = { idagent }
        const query = await fetch(`${URL_ROUTE.LISTALLUSER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentAll(idagent) {
        let data = { idagent };
        const query = await fetch(`${URL_ROUTE.LISTAGENTALL}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatestatus(id, observation, status) {
        let data = { id, observation, status };
        const query = await fetch(`${URL_ROUTE.UPDATESTATUS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentAllFilter(idagent, idstatus, day) {
        let data = { idagent, idstatus }
        const query = await fetch(`${URL_ROUTE.LISTAGENTALLFILTER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentFilter(idagent, day) {
        let data = { idagent }
        const query = await fetch(`${URL_ROUTE.LISTAGENTFILTER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listAgentAllFilterStatus(idstatus, idagent, day) {
        let data = { idstatus, idagent }
        const query = await fetch(`${URL_ROUTE.LISTAGENTFILTERSTATUS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    // USERS
    async createuser(email, name, lastname, password, idprofile, checkagency) {
        let data = { email, name, lastname, password, idprofile, checkagency }
        const query = await fetch(`${URL_ROUTE.CREATEUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listusers() {
        const query = await fetch(`${URL_ROUTE.LISTUSERS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async updateusers(id, name, lastname, idprofile) {
        let data = { id, name, lastname, idprofile }
        const query = await fetch(`${URL_ROUTE.UPDATEUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateStatus(id, estado) {
        let data = { id, estado }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatePass(id, password) {
        let data = { id, password }
        const query = await fetch(`${URL_ROUTE.UPDATEPASSUSERS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listagent() {
        const query = await fetch(`${URL_ROUTE.LISTAGENT}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async listagentON() {
        const query = await fetch(`${URL_ROUTE.LISTAGENTON}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async reportLogin(dateStart, dateEnd, iduser) {
        let data = {
            dateStart, dateEnd, iduser
        }
        const query = await fetch(`${URL_ROUTE.REPORTLOGIN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    // AGENT
    async createassignedagent(name, email, phone, password) {
        let data = { name, email, phone, password }
        const query = await fetch(`${URL_ROUTE.CREATEASSIGNEDAGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateassignedagent(id, name, email, phone) {
        let data = { id, name, email, phone }
        const query = await fetch(`${URL_ROUTE.UPDATEASSIGNEDAGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updatestatusagent(id, status) {
        let data = { id, status }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSAGENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listagentassigned() {
        const query = await fetch(`${URL_ROUTE.LISTASSIGNEDAGENT}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async updateformobamacare(id, idpatient, npi, name, lastname, age, phone, email, address, zone, idstatusmigratorie, work, assuremedic, ssn, accountbank, dependients, incometax, nameuser, date, hour, description, code, uploadAudio) {
        let data = new FormData();
        data.append('id', id);
        data.append('idpatient', idpatient);
        data.append('npi', npi);
        data.append('name', name);
        data.append('lastname', lastname);
        data.append('age', age);
        data.append('phone', phone);
        data.append('email', email);
        data.append('address', address);
        data.append('zone', zone);
        data.append('idstatusmigratorie', idstatusmigratorie);
        data.append('work', work);
        data.append('assuremedic', assuremedic);
        data.append('ssn', ssn);
        data.append('accountbank', accountbank);
        data.append('dependients', dependients);
        data.append('incometax', incometax);
        data.append('nameuser', nameuser);
        data.append('date', date);
        data.append('hour', hour);
        data.append('description', description);
        data.append('code', code);
        data.append('uploadAudio', uploadAudio);

        const query = await fetch(`${URL_ROUTE.UPDATE_FORMOBAMACARE}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data
        });
        return query
    }

    // CREATE LIST PHONE CARE CROSS
    async createlistphonecarecross(datecreate, iduser, nameuser, phone) {
        let data = { datecreate, iduser, nameuser, phone }
        const query = await fetch(`${URL_ROUTE.CREATELISTCARECROSS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listphonecarecross() {
        const query = await fetch(`${URL_ROUTE.LISTPHONECARECROSS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return query
    }

    async reportstatusphone(idstatus) {
        let data = {
            idstatus
        }
        const query = await fetch(`${URL_ROUTE.REPORTSTATUSPHONE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async reportnumbernoansweragent(idstatus, idstatus2, user_id) {
        let data = {
            idstatus, idstatus2, user_id
        }
        const query = await fetch(`${URL_ROUTE.REPORTSTATUSPHONENOANSWERAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deleteappointment(idappointment) {
        let data = {
            idappointment
        }
        const query = await fetch(`${URL_ROUTE.DELETEAPPOINTMENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updatestatusphoneagent(id, id_agent, id_status) {
        let data = {
            id, id_agent, id_status
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSPHONEAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    /* ------------------------------------------------------ */

    async createsales(id_salesman, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, user_id_agency, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse) {
        let data = new FormData()
        data.append('id_salesman', id_salesman)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('user_id_agency', user_id_agency)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        const query = await fetch(`${URL_ROUTE.CREATESALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async createsalesPending(id_salesman, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, user_id_agency, observation_immigration, observation_immigration2, observation2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse) {
        let data = new FormData()
        data.append('id_salesman', id_salesman)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('user_id_agency', user_id_agency)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('observation2', observation2)
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        const query = await fetch(`${URL_ROUTE.CREATESALESPENDING}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async createsalesDraftAgent(id_salesman, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, user_id_agency, observation_immigration, observation_immigration2, observation2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse) {
        let data = new FormData()
        data.append('id_salesman', id_salesman)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('user_id_agency', user_id_agency)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('observation2', observation2)
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        const query = await fetch(`${URL_ROUTE.CREATESALESDRAFTAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesales(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        const query = await fetch(`${URL_ROUTE.UPDATESALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesProcess(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question, project_in = null) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        data.append('project_in', project_in)
        const query = await fetch(`${URL_ROUTE.UPDATESALESPROCESS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesAgent(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question, project_in = null) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        data.append('project_in', project_in)
        const query = await fetch(`${URL_ROUTE.UPDATESALESAGENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesData(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        const query = await fetch(`${URL_ROUTE.UPDATESALESDATA}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesDataOther(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, idsecurity_question, security_question, id_status, project_in) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', idsecurity_question)
        data.append('security_question_response', security_question)
        data.append('id_status', id_status)
        data.append('project_in', project_in)
        const query = await fetch(`${URL_ROUTE.UPDATESALESDATAOTHER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updatesalesDataRecovery(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, observation_immigration, observation_immigration2, callOrigin, user_name, recovery, user_id, genderApplicant, genderApplicant2) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('id_salesman', user_id)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('recovery', recovery)
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        const query = await fetch(`${URL_ROUTE.UPDATESALESDATARECOVERY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updateSalesFile(id_sales, idfiles, uploadfiles) {
        let data = new FormData()
        data.append('id_sales', id_sales)
        data.append('idfiles', idfiles)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        const query = await fetch(`${URL_ROUTE.UPDATESALESFILE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

    async updateSalesVerifyBackOffice(id_sales) {
        let data = {
            id_sales
        }
        const query = await fetch(`${URL_ROUTE.UPDATESALESVERIFYBACKOFFICE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateUploadSalesDocument(arrayFiles) {
        let data = {
            arrayFiles
        }
        const query = await fetch(`${URL_ROUTE.UPDATEUPLOADSALESDOCUMENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateStatusFinish(idsales, idstatus_finish, iduser, nameuser) {
        let data = {
            id: idsales, idstatus_finish, iduser, nameuser
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSFINISH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateStatusFinishNPN(idsales, idstatus_finish, npn, iduser, nameuser) {
        let data = {
            id: idsales, idstatus_finish, npn, iduser, nameuser
        }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSFINISHNPN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchsalespre(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPRE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesdepent(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESDEPENDENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesplan(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPLAN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesbeneficiaries(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESBENEFICIARIES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchuploadsalesdocument(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHUPLOADSALESDOCUMENT}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async deleteuploadsales(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.DELETEUPLOADSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesagents(id, id_user) {
        let data = {
            id, id_user
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESAGENTS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessor(id, id_agency) {
        let data = {
            id, id_agency
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessorBO(id, id_agency) {
        let data = {
            id, id_agency
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSORBO}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesprocessorRecovery(id, id_agency) {
        let data = {
            id, id_agency
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPROCESSORRECOVERY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }
    async searchsalesobservation(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESOBSERVATION}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async exportFileXLXS(filename, tableOrAll, typeSale, filterdate, filterEndDate, filterphone, filterfullname, stateFilter, typeCallFilter) {
        let data = {
            filename, tableOrAll, typeSale, filterdate: filterdate !== "" ? filterdate : '-',
            filterEndDate: filterEndDate !== "" ? filterEndDate : '-',
            filterphone: filterphone !== "" ? filterphone : '-',
            filterfullname: filterfullname !== "" ? filterfullname : '-',
            stateFilter: stateFilter !== "" ? stateFilter : '-',
            typeCallFilter: typeCallFilter !== "" ? typeCallFilter : '-'
        }
        const query = await fetch(`${URL_ROUTE.EXPORTFILESXLXS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async exportFileXLXSProcessor(filename, typeSale, filterdate, filterEndDate, filterphone, filterfullname, stateFilter, typeCallFilter) {
        let data = {
            filename, typeSale, filterdate: filterdate !== "" ? filterdate : '-',
            filterEndDate: filterEndDate !== "" ? filterEndDate : '-',
            filterphone: filterphone !== "" ? filterphone : '-',
            filterfullname: filterfullname !== "" ? filterfullname : '-',
            stateFilter: stateFilter !== "" ? stateFilter : '-',
            typeCallFilter: typeCallFilter !== "" ? typeCallFilter : '-'
        }
        const query = await fetch(`${URL_ROUTE.EXPORTFILESXLXSPROCESSOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async exportFileXLXSQuality(filename, typeSale, filterdate, filterEndDate, stateFilter) {
        let data = {
            filename, typeSale, filterdate: filterdate !== "" ? filterdate : '-',
            filterEndDate: filterEndDate !== "" ? filterEndDate : '-',
            stateFilter: stateFilter !== "" ? stateFilter : '-',
        }
        const query = await fetch(`${URL_ROUTE.EXPORTFILESXLXSQUALITY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deleteFileXLXS(filename) {
        let data = { filename }
        const query = await fetch(`${URL_ROUTE.DELETEFILESXLXS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    /* ---------------------------------------------- */

    async listprenewappointmentsales(id_status) {
        let data = {
            id_status
        }
        const query = await fetch(`${URL_ROUTE.LISTPRENEWAPPOINTMENTSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listappointmentsalesdate(date1, date2, id_status) {
        let data = {
            date1, date2, id_status
        }
        const query = await fetch(`${URL_ROUTE.LISTAPPOINTMENTSALESDATE}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchappointmentsales(id_sales) {
        let data = {
            id_sales
        }
        const query = await fetch(`${URL_ROUTE.SEARCHAPPOINTMENTSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async searchsales(id_status) {
        let data = {
            id_status
        }
        const query = await fetch(`${URL_ROUTE.SEACRHSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async updateformsales(id, id_patient, name, lastname, email, phone, datebirth, age, migratory, work, insurance, code, dependents, tax, ss, direction, phoneConyuge, alien, card, cat, expDate, folio, dataRow, principalIncome,
        incomeConyge, totalIncome, employer, phoneEmployer, w2, bank, accountNumber, routingNumber, automaticPayment, payDay, debit, credit, cardNumber, expDateCard, cvcCode, insurer, premium, npnUsed, referral, marketplace,
        deadline, typePlan, deductible, max, nameNpn, supplementalBenefit, policyNumber, beneficiary, type_bank, detail, observation, agency) {
        let data = {
            id, id_patient, name, lastname, email, phone, datebirth, age, migratory, work, insurance, code, dependents, tax, ss, direction, phoneConyuge, alien, card, cat, expDate, folio, dataRow, principalIncome,
            incomeConyge, totalIncome, employer, phoneEmployer, w2, bank, accountNumber, routingNumber, automaticPayment, payDay, debit, credit, cardNumber, expDateCard, cvcCode, insurer, premium, npnUsed,
            referral, marketplace, deadline, typePlan, deductible, max, nameNpn, supplementalBenefit, policyNumber, beneficiary, type_bank, detail, observation, agency
        }
        const query = await fetch(`${URL_ROUTE.UPDATEFORMSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deletesales(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.DELETESALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async uploadformsuplemental(name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation, user_id, nameuser) {
        let data = {
            name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation, user_id, nameuser
        }
        const query = await fetch(`${URL_ROUTE.UPLOADFORMSUPLEMENTAL}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listformsuplemental(idstatus) {
        let data = {
            idstatus
        }
        const query = await fetch(`${URL_ROUTE.LISTFORMSUPLEMENTAL}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateformsuplemental(id_suplemental, id_patient, name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation/* , agency */) {
        let data = {
            id_suplemental, id_patient, name, lastname, email, phone, datebirth, height, weight, age, zone, migratory, work, assuremedic, code, dependients, incometax, companyname, supplementalbenefit, value, observation/* , agency */
        }
        const query = await fetch(`${URL_ROUTE.UPDATEFORMSUPLEMENTAL}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async deletesuplemental(id) {
        let data = {
            id
        }
        const query = await fetch(`${URL_ROUTE.DELETESUPLEMENTAL}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    /* AGENCY */
    async listagency() {
        const query = await fetch(`${URL_ROUTE.LISTAGENCY}`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
        });
        return query
    }

    async createagency(nameagency, email, id_user) {
        let data = {
            nameagency,
            email,
            id_user
        }
        const query = await fetch(`${URL_ROUTE.CREATEAGENCY}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateagency(id, nameagency, email) {
        let data = {
            id,
            nameagency,
            email
        }
        const query = await fetch(`${URL_ROUTE.UPDATEAGENCY}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async updateStatusSales(id, idstatus, observation, user_id, nameuser) {
        let data = { id, idstatus, observation, user_id, nameuser }
        const query = await fetch(`${URL_ROUTE.UPDATESTATUSSALES}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createLogCMSSales(id, url_cms, nameuser) {
        let data = { id, url_cms, nameuser }
        const query = await fetch(`${URL_ROUTE.CREATELOGCMSSALES}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSales(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALES}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesTransfer(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESTRANSFER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesLastDays(policytype) {
        let data = { policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESLASTDAYS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgents(date, policytype) {
        let data = { date, policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsPlanDay(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSPLANDAY}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsPlanMonth(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSPLANMONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyDay(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYDAY}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyMonth(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYMONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesCompanyYear(date, salesman) {
        let data = { date, salesman }
        const query = await fetch(`${URL_ROUTE.TOTALSALESCOMPANYYEAR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonth(date, policytype) {
        let data = { date, policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTH}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonthUser(user_id, date, policytype) {
        const data = {
            user_id, date, policytype
        }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTHUSER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesAgentsUser(user_id, date, policytype) {
        const data = {
            user_id, date, policytype
        }
        const query = await fetch(`${URL_ROUTE.TOTALSALESAGENTSUSER}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesMonthLastVsCurrent(date, policytype) {
        const data = { date, policytype }
        const query = await fetch(`${URL_ROUTE.TOTALSALESMONTHLASTVSCURRENT}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async totalSalesForPlan(date) {
        const data = { date }
        const query = await fetch(`${URL_ROUTE.TOTALSALESFORPLAN}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listYears() {
        const query = await fetch(`${URL_ROUTE.LISTYEARS}`, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async listGoals(year) {
        const data = {
            year
        }
        const query = await fetch(`${URL_ROUTE.LISTGOALS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createGoals(month) {
        const data = {
            month
        }
        const query = await fetch(`${URL_ROUTE.CREATEGOALS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async editGoals(id, goal, goalagent) {
        const data = {
            id, goal, goalagent
        }
        const query = await fetch(`${URL_ROUTE.EDITGOALS}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async createGoalPremiumYear(year, goal, uploadfiles) {
        let data = new FormData();
        data.append('year', year)
        data.append('goal', goal)
        uploadfiles.map(upload => {
            data.append('uploadfiles', upload)
        })
        const query = await fetch(`${URL_ROUTE.CREATEGOALPREMIUMYEAR}`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data
        });
        return query
    }

    async searchNotaPad(user_id) {
        let data = {
            user_id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHNOTEPAD}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveNotaPad(user_id, text) {
        let data = {
            user_id, text: text != '' ? text : '-'
        }
        const query = await fetch(`${URL_ROUTE.SAVENOTEPAD}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async saveNoteColumns(user_id, tableColumns) {
        let data = {
            user_id, tableColumns
        }
        const query = await fetch(`${URL_ROUTE.SAVENOTECOLUMNS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchNoteColumns(user_id) {
        let data = {
            user_id
        }
        const query = await fetch(`${URL_ROUTE.SEARCHNOTECOLUMNS}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchGoalPremiumYear(year) {
        let data = {
            year
        }
        const query = await fetch(`${URL_ROUTE.SEARCHGOALPREMIUMYEAR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listSalesSupervisor(id_status) {
        let data = { id_status }
        const query = await fetch(`${URL_ROUTE.LISTSALESSUPERVISOR}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async listSalesBackOffice(id_status) {
        let data = { id_status }
        const query = await fetch(`${URL_ROUTE.LISTSALESBACKOFFICE}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async verifyNumberPlan(phone) {
        let data = { phone }
        const query = await fetch(`${URL_ROUTE.VERIFYNUMBERPLAN}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchsalesquality(id_status, idstatus_finish) {
        let data = { id_status, idstatus_finish }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESQUALITY}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async searchVerifyMonth(year, idsale) {
        let data = { year, idsale }
        const query = await fetch(`${URL_ROUTE.SEARCHVERIFYMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async insertVerifyMonth(year, idsale, january, febrary, march, april, may, june, july, august, september, october, november, december) {
        let data = { year, idsale,
            january: january != "" && january != undefined ? january : "-",
            febrary: febrary != "" && febrary != undefined ? febrary : "-",
            march: march != "" && march != undefined ? march : "-",
            april: april != "" && april != undefined ? april : "-",
            may: may != "" && may != undefined ? may : "-",
            june: june != "" && june != undefined ? june : "-",
            july: july != "" && july != undefined ? july : "-",
            august: august != "" && august != undefined ? august : "-",
            september: september != "" && september != undefined ? september : "-",
            october: october != "" && october != undefined ? october : "-",
            november: november != "" && november != undefined ? november : "-",
            december: december != "" && december != undefined ? december : "-" }
        console.log(data, "data")
        const query = await fetch(`${URL_ROUTE.INSERTVERIFYMONTH}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
        });
        return query
    }

    async reportsFilters(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, id_status, salesman = '-', idstatus_finish = '-', typeCallFilter = '-', recovery = '-') {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
            id_status, salesman: String(salesman), idstatus_finish: String(idstatus_finish),
            typeCallFilter: typeCallFilter == '' ? '-' : typeCallFilter, recovery
        }
        const query = await fetch(`${URL_ROUTE.REPORTSFILTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async exportDataExcelFilters(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales, id_status, salesman = '-', idstatus_finish = '-', typeCallFilter = '-', recovery = '-') {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
            id_status, salesman: String(salesman), idstatus_finish: String(idstatus_finish),
            typeCallFilter: typeCallFilter == '' ? '-' : typeCallFilter, recovery
        }
        const query = await fetch(`${URL_ROUTE.EXPORTDATAEXCELFILTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            responseType: 'blob',
            body: JSON.stringify(data)
        });
        return query
    }

    async listSalesBackOfficeFilter(filterdate, filterEndDate, filterphone, filterfullname, stateFilter, policyTypeFilter, filterIdSales) {
        let data = {
            filterdate: filterdate == '' || filterdate == null ? '-' : filterdate,
            filterEndDate: filterEndDate == '' || filterEndDate == null ? '-' : filterEndDate,
            filterphone: filterphone == '' ? '-' : filterphone,
            filterfullname: filterfullname == '' ? '-' : filterfullname,
            stateFilter: stateFilter == '' ? '-' : stateFilter,
            policyTypeFilter: policyTypeFilter == '' ? '-' : policyTypeFilter,
            filterIdSales: filterIdSales == '' ? '-' : filterIdSales,
        }
        const query = await fetch(`${URL_ROUTE.LISTSALESBACKOFFICEFILTER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async listHostTransfers() {
        const query = await fetch(`${URL_ROUTE.LISTHOSTTRANSFERS}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
        return query
    }

    async searchsalespreOther(id_status) {
        const data = {
            id_status
        }
        const query = await fetch(`${URL_ROUTE.SEARCHSALESPREOTHER}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        return query
    }

    async TransferSales(id_sales, id_patient, agentname, referrded, date, name, lastname, phone, state, address, npn, otherparty, company, year, metal, deductible, omp, monthly, erc,
        uc, pd, sd, gd, applicant, phone1, dob, email, ss, enrolled, height, weight, immigration, residence, exp2, applicantspouse, phone2, dob2, email2, ss2, enrolled2,
        immigration2, residence2, exp3, language, maritalstatus, address2, howtopay, employer, occupation, totalanual, color, ping, bank, routing, account, cardname,
        card, exp, cvc, rowobservation, tableSuplemental, tablebeneficiaries, dataRow, uploadfiles, observation_immigration, observation_immigration2, callOrigin, user_name, genderApplicant, genderApplicant2, securityQuestion, securityQuestionResponse, projectDataBase, project_in) {
        let data = new FormData()
        console.log(id_sales, "id_sales");
        data.append('id_sales', id_sales)
        data.append('id_patient', id_patient)
        data.append('agentname', agentname)
        data.append('referrded', referrded)
        data.append('date', date)
        data.append('name', name)
        data.append('lastname', lastname)
        data.append('phone', phone)
        data.append('state', state)
        data.append('address', address)
        data.append('npn', npn)
        data.append('otherparty', otherparty)
        data.append('company', company)
        data.append('year', year)
        data.append('metal', metal)
        data.append('deductible', deductible)
        data.append('omp', omp)
        data.append('monthly', monthly)
        data.append('erc', erc)
        data.append('uc', uc)
        data.append('pd', pd)
        data.append('sd', sd)
        data.append('gd', gd)
        data.append('applicant', applicant)
        data.append('phone1', phone1)
        data.append('dob', dob)
        data.append('email', email)
        data.append('ss', ss)
        data.append('enrolled', enrolled)
        data.append('height', height)
        data.append('weight', weight)
        data.append('immigration', immigration)
        data.append('residence', residence)
        data.append('exp2', exp2)
        data.append('applicantspouse', applicantspouse)
        data.append('phone2', phone2)
        data.append('dob2', dob2)
        data.append('email2', email2)
        data.append('ss2', ss2)
        data.append('enrolled2', enrolled2)
        data.append('immigration2', immigration2)
        data.append('residence2', residence2)
        data.append('exp3', exp3)
        data.append('language', language)
        data.append('maritalstatus', maritalstatus)
        data.append('address2', address2)
        data.append('howtopay', howtopay)
        data.append('employer', employer)
        data.append('occupation', occupation)
        data.append('totalanual', totalanual)
        data.append('color', color)
        data.append('ping', ping)
        data.append('bank', bank)
        data.append('routing', routing)
        data.append('account', account)
        data.append('cardname', cardname)
        data.append('card', card)
        data.append('exp', exp)
        data.append('cvc', cvc)
        data.append('rowobservation', JSON.stringify(rowobservation))
        data.append('tableSuplemental', JSON.stringify(tableSuplemental))
        data.append('tablebeneficiaries', JSON.stringify(tablebeneficiaries))
        data.append('dataRow', JSON.stringify(dataRow))
        data.append('callOrigin', callOrigin)
        data.append('user_name', user_name)
        data.append('uploadfiles', JSON.stringify(uploadfiles))
        data.append('observation_immigration', observation_immigration)
        data.append('observation_immigration2', observation_immigration2)
        data.append('genderApplicant', genderApplicant)
        data.append('genderApplicant2', genderApplicant2)
        data.append('idsecurity_question', securityQuestion)
        data.append('security_question_response', securityQuestionResponse)
        data.append('project', projectDataBase)
        data.append('project_in', project_in)
        const query = await fetch(`${URL_ROUTE.TRANSFERSALES}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        });
        return query
    }

}

export default new Api();